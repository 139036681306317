<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div>
                    <b-card-group>
                        <div class="col-md-6" v-if="this.tranExist">
                            <b-card
                                no-body
                                class="mb-1"
                                align="left"
                                v-if="transaction.length > 0"
                            >
                                <b-card-body>
                                    <b-card-text class="header-log transactions-log"
                                    >Chi tiết giao dịch
                                    </b-card-text
                                    >
                                    <b-table
                                        :fields="transactionField"
                                        :items="transaction"
                                        caption-top
                                        responsive
                                        stacked
                                    >
                                        <template #cell(origin_status)="data">
                                            <strong
                                                :style="getColorStatus(data.value, 'TRANSACTION')"
                                            >{{ getTextStatus(data.value) }}</strong>
                                        </template>

                                      <template #cell(verify)="data">
                                        <span v-if="data.item.verify">
                                          {{ data.item.verify.verify_type }}
                                        </span>
                                      </template>
                                      <template #cell(verify_date)="data">
                                        <span v-if="data.item.verify">
                                          {{ data.item.verify.verify_at }}
                                        </span>
                                      </template>

                                    </b-table>
                                </b-card-body>
                            </b-card>
                        </div>
                        <div class="col-md-6" v-if="order.length > 0">
                            <b-card no-body class="mb-4">
                                <b-card-body>
                                    <b-card-text class="header-log order-log"
                                    >Chi tiết hóa đơn
                                    </b-card-text
                                    >
                                    <b-table
                                        :fields="OrderFields"
                                        :items="order"
                                        caption-top
                                        responsive
                                        stacked
                                    >
                                        <template #cell(status)="data">
                                            <strong
                                                :style="getColorStatus(data.value, 'TRANSACTION')"
                                            >
                                                {{ data.value }}
                                            </strong>
                                        </template>
                                        <template #cell(completed_at)="data">
                                            {{ formatDateTime(data.item.completed_at) }}
                                        </template>
                                        <template #cell(meta_data)="data">
                                            <b-card no-body class="mb-1">
                                                <a
                                                    v-b-toggle.collapse-detail
                                                    class="
                            viewmore
                            btn btn-info
                            font-weight-bold
                            btn-sm
                            float-left
                          "
                                                >
                                                    Xem thông tin
                                                </a>
                                                <b-collapse id="collapse-detail" role="tabpanel">
                                                    <b-card-body>
                                                        <ul id="v-for-object" v-if="data.value">
                                                            <li
                                                                v-for="(value, key) in data.value"
                                                                v-bind:key="key"
                                                            >
                                                                {{ key }} : {{ value }}
                                                            </li>
                                                        </ul>
                                                        <ul v-else>
                                                            Không có thông tin
                                                        </ul>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </template>
                                        <template #cell(items)="data">
                                            <b-card no-body class="mb-1">
                                                <a
                                                    v-b-toggle.collapse-detail-card
                                                    class="
                            viewmore
                            btn btn-info
                            font-weight-bold
                            btn-sm
                            float-left
                          "
                                                >
                                                    Xem thông tin thẻ
                                                </a>
                                                <b-collapse id="collapse-detail-card" role="tabpanel">
                                                    <b-card-body>
                                                        <b-table
                                                            v-if="data.value && data.value.length > 0"
                                                            :fields="[
                                                                {
                                                                    key:'id',
                                                                    label:'ID'
                                                                },
                                                                {
                                                                    key:'type',
                                                                    label:'Mạng'
                                                                },
                                                                {
                                                                    key:'price',
                                                                    label:'price'
                                                                },
                                                                {
                                                                    key:'serial',
                                                                    label:'Serial'
                                                                },
                                                                {
                                                                    key:'expired_at',
                                                                    label:'Hạn sử dụng'
                                                                },
                                                            ]"
                                                            :items="data.value[0].product.cards"
                                                            caption-top
                                                            responsive
                                                            stacked
                                                        >
                                                            <template #cell(price)="item">
                                                                {{ numberFormat(item.value) }}
                                                            </template>
                                                            <template #cell(expired_at)="item">
                                                                {{ formatDateTime(item.item.expried_at) }}
                                                            </template>
                                                        </b-table>
                                                        <ul v-else>
                                                            Không có thông tin
                                                        </ul>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </template>
                                        <template #cell(transactionLoyaty)="data">
                                            <div class="tesst">
                                                <a
                                                    v-on:click="pushPoint()"
                                                    class="btn btn-primary font-weight-bold btn-sm float-left"
                                                >
                                                    Bù point ngay
                                                </a>
                                            </div>
                                            <b-modal
                                                ref="popup-alert"
                                                @hide="modalHidden"
                                                hide-footer
                                            >
                                                <div class="d-block text-center">
                                                    <div class="d-flex justify-content-center mb-4">
                                                        <div v-html="icon"></div>
                                                    </div>
                                                    <h4>{{ message_point }}</h4>
                                                </div>
                                                <div class="d-flex justify-content-end mt-5">
                                                    <b-button
                                                        class="close_bt"
                                                        variant="primary"
                                                        block
                                                        @click="closePopup()"
                                                    >OK
                                                    </b-button
                                                    >
                                                </div>
                                            </b-modal>

                                        </template>
                                    </b-table>
                                </b-card-body>
                            </b-card>
                        </div>

                        <div
                            class="col-md-6"
                            v-if="action.length > 0 && merchant.length > 0"
                        >
                            <b-card no-body class="mb-2">
                                <b-card-body>
                                    <b-card-text class="header-log action-log"
                                    >Chi tiết Action
                                    </b-card-text
                                    >
                                    <b-table
                                        :fields="ActionFields"
                                        :items="action"
                                        caption-top
                                        responsive
                                        stacked
                                    >
                                        <template #cell(status)="data">
                                            <strong
                                                :style="getColorStatus(data.value, 'TRANSACTION')"
                                            >{{ data.value }}</strong
                                            >
                                        </template>
                                        <template #cell(payment_at)="data">
                                            {{ formatDateTime(data.item.payment_at) }}
                                        </template>
                                        <template #cell(created_at)="data">
                                            {{ formatDateTime(data.item.created_at) }}
                                        </template>
                                        <template #cell(updated_at)="data">
                                            {{ formatDateTime(data.item.updated_at) }}
                                        </template>
                                        <template #cell(reason_description)="data">
                                            {{data.item.action_detail.description}}
                                        </template>
                                    </b-table>
                                </b-card-body>
                            </b-card>
                            <b-card no-body class="mb-2">
                                <b-card-body>
                                    <b-card-text class="header-log merchant-log"
                                    >Chi tiết đối tác
                                    </b-card-text
                                    >
                                    <b-table
                                        :fields="MerchantField"
                                        :items="merchant"
                                        caption-top
                                        responsive
                                        stacked
                                    >
                                        <template #cell(status)="data">
                                            <strong
                                                :style="getColorStatus(data.value, 'TRANSACTION')"
                                            >{{ data.value }}</strong
                                            >
                                        </template>
                                    </b-table>
                                </b-card-body>
                            </b-card>
                        </div>
                        <div
                            class="col-md-6"
                            v-else-if="
                action.length > 0 && merchant.length > 0 && transfer.length > 0
              "
                        >
                            <b-card no-body class="mb-2">
                                <b-card-body>
                                    <b-card-text class="header-log action-log"
                                    >Chi tiết Action
                                    </b-card-text
                                    >
                                    <b-table
                                        :fields="ActionFields"
                                        :items="action"
                                        caption-top
                                        responsive
                                        stacked
                                    >
                                        <template #cell(status)="data">
                                            <strong
                                                :style="getColorStatus(data.value, 'TRANSACTION')"
                                            >{{ data.value }}</strong
                                            >
                                        </template>
                                        <template #cell(payment_at)="data">
                                            {{ formatDateTime(data.item.payment_at) }}
                                        </template>
                                        <template #cell(created_at)="data">
                                            {{ formatDateTime(data.item.created_at) }}
                                        </template>
                                        <template #cell(updated_at)="data">
                                            {{ formatDateTime(data.item.updated_at) }}
                                        </template>
                                        <template #cell(reason_description)="data">
                                            {{data.item.action_detail.description}}
                                        </template>
                                    </b-table>
                                </b-card-body>
                            </b-card>
                            <b-card no-body class="mb-2">
                                <b-card-body>
                                    <b-card-text class="header-log merchant-log"
                                    >Chi tiết đối tác
                                    </b-card-text
                                    >
                                    <b-table
                                        :fields="MerchantField"
                                        :items="merchant"
                                        caption-top
                                        responsive
                                        stacked
                                    >
                                        <template #cell(status)="data">
                                            <strong
                                                :style="getColorStatus(data.value, 'TRANSACTION')"
                                            >{{ data.value }}</strong
                                            >
                                        </template>
                                    </b-table>
                                </b-card-body>
                            </b-card>
                            <b-card no-body class="mb-2">
                                <b-card-body>
                                    <b-card-text class="header-log receiver-detail"
                                    >Chi tiết người nhận
                                    </b-card-text
                                    >
                                    <b-table
                                        :fields="TransferFields"
                                        :items="transfer"
                                        caption-top
                                        responsive
                                        stacked
                                    >
                                    </b-table>
                                </b-card-body>
                            </b-card>
                        </div>
                        <div
                            v-else-if="
                action.length > 0 || merchant.length > 0 || transfer.length > 0
              "
                            class="col-md-6"
                        >
                            <div v-if="action.length > 0">
                                <b-card no-body class="mb-2">
                                    <b-card-body>
                                        <b-card-text class="header-log action-log"
                                        >Chi tiết Action
                                        </b-card-text
                                        >
                                        <b-table
                                            :fields="ActionFields"
                                            :items="action"
                                            caption-top
                                            responsive
                                            stacked
                                        >
                                            <template #cell(status)="data">
                                                <strong
                                                    :style="getColorStatus(data.value, 'TRANSACTION')"
                                                >{{ data.value }}</strong
                                                >
                                            </template>
                                            <template #cell(payment_at)="data">
                                                {{ formatDateTime(data.item.payment_at) }}
                                            </template>
                                            <template #cell(created_at)="data">
                                                {{ formatDateTime(data.item.created_at) }}
                                            </template>
                                            <template #cell(updated_at)="data">
                                                {{ formatDateTime(data.item.updated_at) }}
                                            </template>
                                            <template #cell(reason_description)="data">
                                                {{data.item.action_detail.description}}
                                            </template>
                                        </b-table>
                                    </b-card-body>
                                </b-card>
                            </div>
                            <div v-if="merchant.length > 0">
                                <b-card no-body class="mb-2">
                                    <b-card-body>
                                        <b-card-text class="header-log merchant-log"
                                        >Chi tiết đối tác
                                        </b-card-text
                                        >
                                        <b-table
                                            :fields="MerchantField"
                                            :items="merchant"
                                            caption-top
                                            responsive
                                            stacked
                                        >
                                            <template #cell(status)="data">
                                                <strong
                                                    :style="getColorStatus(data.value, 'TRANSACTION')"
                                                >{{ data.value }}</strong
                                                >
                                            </template>
                                        </b-table>
                                    </b-card-body>
                                </b-card>
                            </div>
                            <div v-else-if="transfer.length > 0">
                                <b-card no-body class="mb-2">
                                    <b-card-body>
                                        <b-card-text>Chi tiết người nhận</b-card-text>
                                        <b-table
                                            :fields="TransferFields"
                                            :items="transfer"
                                            caption-top
                                            responsive
                                            stacked
                                        >
                                        </b-table>
                                    </b-card-body>
                                </b-card>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="depositTransaction.length > 0">
                            <b-card no-body class="mb-2">
                                <b-card-body>
                                    <b-card-text
                                        class="card-text header-log transaction-deposit-log"
                                    >Giao dịch nạp tiền
                                    </b-card-text
                                    >
                                    <b-table
                                        :fields="DepositTransactionFields"
                                        :items="depositTransaction"
                                        caption-top
                                        responsive
                                        stacked
                                    >
                                        <template #cell(status)="data">
                                            <strong
                                                :style="getColorStatus(data.value, 'TRANSACTION')"
                                            >{{ data.value }}</strong
                                            >
                                        </template>
                                    </b-table>
                                </b-card-body>
                            </b-card>
                        </div>
                        <div class="col-md-6" v-if="log.length > 0">
                            <b-card no-body class="mb-4">
                                <b-card-body>
                                    <b-card-text class="header-log merchant-response-log"
                                    >Log đối tác
                                    </b-card-text
                                    >
                                    <b-table
                                        :fields="LogField"
                                        :items="log"
                                        caption-top
                                        responsive
                                        stacked
                                    >
                                        <template #cell(card_info)="data">
                                            <span v-html="data.value">{{ data.value }}</span>
                                        </template>
                                        <template #cell(bank_raw_response)="data">
                                            <span v-html="data.value">{{ data.value }}</span>
                                        </template>
                                        <template #cell(extra_data)="data">
                                            <span v-html="data.value">{{ data.value }}</span>
                                        </template>

                                    </b-table>
                                    <a
                                        v-if="orderData.type && ['TOPUP_MOBILE', 'BILLING', 'TOPUP_DATA', 'BUY_CARD'].indexOf(orderData.type) > -1"
                                        v-on:click="syncStatus()"
                                        class="btn btn-info font-weight-bold btn-sm float-left"
                                    >
                                        Đồng bộ trạng thái NCC
                                    </a>
                                </b-card-body>
                            </b-card>
                        </div>
                        <div class="col-md-6" v-if="refers.length > 0">
                            <b-card no-body class="mb-4">
                                <b-card-body>
                                    <b-card-text class="header-log merchant-response-log">Giao dịch liên quan
                                    </b-card-text
                                    >
                                    <div class="table-responsive">
                                        <table class="table table-striped table-hover">
                                            <thead>
                                            <tr>
                                                <th>Mã giao dịch</th>
                                                <th>Mã thanh toán</th>
                                                <th>Loại giao dịch</th>
                                                <th>Trạng thái</th>
                                                <th>Ngày tạo</th>
                                                <th>Ngày hoàn thành</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-bind:key="key" v-for="(item, key) in refers">
                                                <td>
                                                    <span v-if="!item.code">---</span>
                                                    <a v-else target="_blank" :href="renderLinkTransaction(item.code)">{{ item.code }}</a>
                                                </td>
                                                <td>
                                                    <span v-if="!item.wallet_transaction_id">---</span>
                                                    <a v-else target="_blank"
                                                       :href="renderLinkWallet(item.wallet_transaction_id)">{{ item.wallet_transaction_id }}</a>
                                                </td>
                                                <td>{{ item.action }}</td>
                                                <td>{{ item.status }}</td>
                                                <td>{{ item.created_at }}</td>
                                                <td>{{ item.completed_at }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </div>
                    </b-card-group>
                    <b-card no-body class="mb-4" v-if="showError" align="center">
                        <b-card-body>
                            <b-card-text>Không tìm thấy giao dịch</b-card-text>
                        </b-card-body>
                    </b-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import TransactionConfig from "@/core/config/transaction.config";
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";

Vue.component("multiselect", Multiselect);
const TransactionRepository = RepositoryFactory.get("transactions");

export default {
    mixins: [Common],
    data: function () {
        return {
            ignoreColumnLogFields:['bank_raw_response','checksum','id','product_id','user_id','order_id','is_used','is_done','is_done_at','created_at','updated_at'],
            transactionField: [
                {
                    key: "id",
                    label: "ID",
                },
                {
                    key: "price",
                    label: "Giá tiền",
                },
                {
                    key: "fee",
                    label: "Phí",
                },
                {
                    key: "discount",
                    label: "Giảm giá",
                },
                {
                    key: "coupon_code",
                    label: "Áp dụng mã giảm giá",
                },
                {
                    key: "amount",
                    label: "Số tiền thực chi",
                },
                {
                    key: "refund_partial",
                    label: "Số tiền đã hoàn",
                },
                {
                    key: "action",
                    label: "Loại",
                },
                {
                    key: "type_text",
                    label: "Loại hành động",
                },
                {
                    key: "user_phone",
                    label: "Tài khoản",
                },
                {
                    key: "source_type",
                    label: "Phương thức thanh toán",
                },
                {
                    key: "origin_status",
                    label: "Trạng thái",
                },
                {
                    key: "transaction_code",
                    label: "Mã giao dịch",
                },
                {
                    key: "card_failure",
                    label: "Nguyên nhân thất bại",
                },
                {
                    key: "response_message",
                    label: "Lý do thất bại",
                },
                {
                    key: "description",
                    label: "Mô tả",
                },
                {
                    key: "created_at",
                    label: "Ngày tạo",
                },
                {
                    key: "updated_at",
                    label: "Ngày cập nhật",
                },
                {
                    key: "completed_at",
                    label: "Ngày hoàn tất",
                },
                {
                    key: "account_source",
                    label: "Tài khoản đích",
                },
                {
                    key: "account_target",
                    label: "Tài khoản nguồn",
                },
              {
                key: "verify",
                label: "Phương thức xác thực",
              },
              {
                key: "verify_date",
                label: "Thời gian xác thực",
              },
                {
                    key: "transaction_code_9s",
                    label: "Mã 9S",
                    tdClass: (value) => {
                        if (!value) return 'force-hidden';
                    }
                }
            ],
            MerchantField: [
                {
                    key: "bank_code",
                    label: "Ngân hàng",
                },
                {
                    key: "type_link",
                    label: "Kênh thanh toán",
                },
                {
                    key: "merchant_transaction_code",
                    label: "Mã đối tác",
                },
                {
                    key: "merchant_transaction_refer",
                    label: "Mã tham chiếu",
                },
            ],
            ActionFields: [
                {
                    key: "id",
                    label: "ID",
                },
                {
                    key: "status_text",
                    label: "Trạng thái",
                },
                {
                    key: "amount",
                    label: "Số tiền",
                },
                {
                    key: "created_at",
                    label: "Ngày tạo",
                },
                {
                    key: "updated_at",
                    label: "Ngày cập nhật",
                },
                {
                    key: "payment_at",
                    label: "Ngày thanh toán",
                },
                {
                  key: "user_action",
                  label: "Người thực hiện",
                },
            ],
            TransferFields: [
                {
                    key: "account_type",
                    label: "Loại",
                },
                {
                    key: "account_no",
                    label: "Số TK/Thẻ",
                },
            ],
            OrderFields: [
                {
                    key: "code",
                    label: "Mã đơn hàng",
                },
                {
                    key: "payment_id",
                    label: "Mã giao dịch",
                },
                {
                    key: "merchant_order_no",
                    label: "Mã đối tác",
                },
                {
                    key: "merchant_code",
                    label: "Tên đối tác",
                },
                {
                    key: "fee",
                    label: "Phí",
                },
                {
                    key: "total",
                    label: "Tổng",
                },
                {
                    key: "type_text",
                    label: "Loại",
                },
                {
                    key: "action_text",
                    label: "Loại hình thức",
                },
                {
                    key: "status_text",
                    label: "Trạng thái",
                },
                {
                    key: "created_at",
                    label: "Ngày tạo",
                },
                {
                    key: "updated_at",
                    label: "Ngày cập nhật",
                },
                {
                    key: "completed_at",
                    label: "Ngày hoàn tất",
                },
                {
                    key: "quantity",
                    label: "Số lượng",
                },
                {
                    key: "product_name",
                    label: "Sản phẩm",
                },
            ],
            DepositTransactionFields: [
                {
                    key: "id",
                    label: "ID",
                },
                {
                    key: "amount",
                    label: "Số tiền",
                },
                {
                    key: "status",
                    label: "Trạng thái",
                },
                {
                    key: "bank_code_text",
                    label: "Ngân hàng",
                },
                {
                    key: "type_link_text",
                    label: "Kênh thanh toán",
                },
                {
                    key: "transaction_id",
                    label: "Mã giao dịch",
                },
            ],
            ReferFields: [],
            LogField: [],
            transaction: [],
            transactionData: {},
            action: [],
            actionData: {},
            deposit: {},
            merchant: [],
            merchantLog: [],
            log: [],
            transfer: [],
            orderData: [],
            order: [],
            refers: [],
            depositTransaction: [],
            showError: false,
            transactionId: "",
            wallet_id: "",
            message_point: "",
            options_service: [
                {value: 'rut_tien', text: 'Rút tiền LK - Rút tiền ví ngân hàng'},
                {value: 'the', text: 'Dịch vụ thẻ'},
                {value: 'veso', text: 'Dịch vụ vé số'},
                {value: 'billing', text: 'Dịch vụ Billing'},
                {value: 'dt_data', text: 'Topup ĐT/data'},
                {value: 'game', text: 'Nạp game'},
                {value: 'khac', text: 'Dịch vụ khác'},
            ],
            reasons:
                {
                    rut_tien: [
                        {value: 'Gián đoạn kết nối ngân hàng thụ hưởng', text: 'Gián đoạn kết nối ngân hàng thụ hưởng'},
                        {
                            value: 'Giao dịch tại thời điểm ngân hàng bảo trì/nâng cấp',
                            text: 'Giao dịch tại thời điểm ngân hàng bảo trì/nâng cấp'
                        }
                    ],
                    the: [
                        {value: 'Giao dịch chưa xuất thẻ thành công', text: 'Giao dịch chưa xuất thẻ thành công'},
                        {value: 'Mệnh giá thẻ đang tạm hết', text: 'Mệnh giá thẻ đang tạm hết'}
                    ],
                    veso: [
                        {value: 'Giao dịch chưa xuất vé số thành công', text: 'Giao dịch chưa xuất vé số thành công'},
                        {
                            value: 'Giao dịch tại thời điểm dịch vụ vé số bảo trì/nâng cấp',
                            text: 'Giao dịch tại thời điểm dịch vụ vé số bảo trì/nâng cấp'
                        }
                    ],
                    billing: [
                        {value: 'Gián đoạn kết nối nhà cung cấp', text: 'Gián đoạn kết nối nhà cung cấp'},
                        {
                            value: 'Mã hóa đơn đang thanh toán tại giao dịch khác',
                            text: 'Mã hóa đơn đang thanh toán tại giao dịch khác'
                        },
                        {
                            value: 'Phát sinh 2 giao dịch thanh toán cho cùng 1 mã hóa đơn trong 10\'',
                            text: 'Phát sinh 2 giao dịch thanh toán cho cùng 1 mã hóa đơn trong 10\''
                        },
                        {
                            value: 'Giao dịch tại thời điểm nhà cung cấp đang bảo trì/nâng cấp',
                            text: 'Giao dịch tại thời điểm nhà cung cấp đang bảo trì/nâng cấp'
                        },
                        {
                            value: 'Nhà cung cấp tạm thời dừng hỗ trợ thanh toán khu vực này',
                            text: 'Nhà cung cấp tạm thời dừng hỗ trợ thanh toán khu vực này'
                        },
                    ],
                    dt_data: [
                        {value: 'Gián đoạn nhà mạng', text: 'Gián đoạn nhà mạng'},
                        {
                            value: 'Giao dịch tại thời điểm nhà mạng đang bảo trì/nâng cấp',
                            text: 'Giao dịch tại thời điểm nhà mạng đang bảo trì/nâng cấp'
                        },
                        {value: 'Sai nhà mạng/SDT đổi nhà mạng', text: 'Sai nhà mạng/SDT đổi nhà mạng'},
                        {
                            value: 'SDT Vinaphone trả sau chưa đăng ký EZPay',
                            text: 'SDT Vinaphone trả sau chưa đăng ký EZPay'
                        },
                        {value: 'SDT đang đăng ký 1 gói data khác', text: 'SDT đang đăng ký 1 gói data khác'},
                    ],
                    game: [
                        {
                            value: 'Giao dịch tại thời điểm nhà cung cấp bảo trì/nâng cấp',
                            text: 'Giao dịch tại thời điểm nhà cung cấp bảo trì/nâng cấp'
                        },
                        {
                            value: 'Giao dịch chưa cung cấp dịch vụ thành công',
                            text: 'Giao dịch chưa cung cấp dịch vụ thành công'
                        },
                    ],
                    khac: [
                        {
                            value: 'Giao dịch chưa cung cấp dịch vụ thành công',
                            text: 'Giao dịch chưa cung cấp dịch vụ thành công'
                        },
                        {
                            value: 'Giao dịch tại thời điểm nhà cung cấp bảo trì/nâng cấp',
                            text: 'Giao dịch tại thời điểm nhà cung cấp bảo trì/nâng cấp'
                        },
                    ]
                },
            queryParam: "",
            tranExist: true,
            actionFieldPlus: [],
            transLoyaty: false,
            transLoyaltyField: [
                {
                    key: "transactionLoyaty",
                    label: "Giao dịch chưa được tích điểm 9Point.",
                },
            ],
            icon:
                '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">\n' +
                '<path fill="#c8e6c9" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><path fill="#4caf50" d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"></path>\n' +
                "</svg>",
        };
    },
    components: {},
    created() {
        this.getTransactionId();
        this.getList();
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Chi tiết giao dịch", route: "detail"},
        ]);
        this.$emit("options_service", {param: this.options_service});
        // this.$emit("selected_service", {param: this.selected_service});
        // this.$emit("selected_reason", {param: this.selected_reason});
        // this.$emit("options_reason", {param: this.options_reason});
        this.$emit("reasons", {param: this.reasons});
        this.$emit("action", {param: this.action});
        this.$emit("deposit", {param: this.deposit});
        this.queryParam = this.$route.query;
        if (this.queryParam.wallet_transaction_id) {
            this.wallet_id = this.queryParam.wallet_transaction_id;
            this.tranExist = false;
            this.actionFieldPlus = [
                {
                    key: "account_source",
                    label: "Tài khoản nguồn",
                },
                {
                    key: "account_target",
                    label: "Tài khoản đích",
                },
                {
                    key: "source_type",
                    label: "Phương thức thanh toán",
                },
                {
                    key: "type_text",
                    label: "Loại hành động",
                },
                {
                  key: "user_action",
                  label: "Người thực hiện",
                },
            ];
            this.ActionFields = [...this.ActionFields, ...this.actionFieldPlus];
        }
        this.$emit("wallet_id", {param: this.wallet_id});

    },
    beforeDestroy() {
        this.$emit("status", {param: undefined});
        this.$emit("trans_status", {param: undefined});
        this.$emit("transaction_id", {param: undefined});
        this.$emit("wallet_id", {param: undefined});
        this.$emit("type_link", {param: undefined});
        this.$emit("transaction", {param: {}});
        this.$emit("order", {param: {}});
        this.$emit("refers", {param: []});
        this.$emit("action", {param: []});
        this.$emit("deposit", {param: []});
    },
    watch: {
        order: function (newVal) {
            this.order = newVal;
            if (
                !this.order[0].transactionLoyaty &&
                this.order[0].status === TransactionConfig.STATUS_ORDER_SUCCESS
            ) {
                this.OrderFields = [...this.OrderFields, ...this.transLoyaltyField];
            }
        },
    },
    methods: {
        async pushPoint() {
            if (confirm("Xác nhận bù point?")) {
                this.$bus.$emit("show-loading", true);
                let params = {
                    code: this.order[0].payment_id,
                };
                let response = await TransactionRepository.doPushPoint(params);
                this.$bus.$emit("show-loading", false);
                this.message_point = response.data.message;
                this.$refs["popup-alert"].show();
                if (response.data.error_code !== 0) {
                    this.icon = `<svg xmlns="http://www.w3.org/2000/svg" height="80px" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>`;
                }
            }
        },
        closePopup() {
            this.$refs["popup-alert"].hide();
            window.location.reload();
        },
        modalHidden() {
            window.location.reload();
        },
        getTransactionId() {
            let transaction_id = this.$route.query.transaction_id;
            let wallet_transaction_id = this.$route.query.wallet_transaction_id;
            if (
                transaction_id === "undefined" ||
                wallet_transaction_id === "undefined"
            ) {
                alert("Thiếu thông tin transaction_id");
                window.history.back();
            }
            this.transactionId = {};
            if (typeof transaction_id !== "undefined") {
                this.transactionId.transaction_id = transaction_id;
                this.wallet_id = wallet_transaction_id;
            }
            if (typeof wallet_transaction_id !== "undefined") {
                this.transactionId.wallet_transaction_id = wallet_transaction_id;
            }
            this.$emit("transaction_id", {param: transaction_id});
        },
        getList: async function () {
            this.$bus.$emit("show-loading", true);
            let response = await TransactionRepository.getDetail(this.transactionId);
            this.$bus.$emit("show-loading", false);
            let body = response.data;
            if (body.error_code === 0) {
                let trans = body.data.transaction;
                // if (trans.wallet_transaction_id) {
                //   this.wallet_transaction_id = trans.wallet_transaction_id;
                // }

                let actions = body.data.action;
                let keyTrans = ["account_source", "account_target", "source_type", "type"];
                let infoAction = {};
                if (!this.tranExist) {
                    Object.keys(trans).forEach((key) => {
                        if (keyTrans.includes(key)) {
                            infoAction[key] = trans[key];
                        }
                    });
                    Object.assign(actions, infoAction);
                }
                let merchant = body.data.merchant;
                let log = body.data.log;
                let transfer = body.data.bank_transfer;
                let depositAction = body.data.depositAction;
                let order = body.data.order;
                let depositTransaction = body.data.depositTransaction ? body.data.depositTransaction : [];
                let merchantLog = body.data.merchant_log ? body.data.merchant_log : [];
                let refers = body.data.refers ? body.data.refers : [];

                this.formatData(
                    trans,
                    actions,
                    merchant,
                    log,
                    transfer,
                    order,
                    depositTransaction,
                    merchantLog,
                    refers,
                    depositAction
                );
            } else {
                this.showError = true;
            }
        },
        formatData: function (
            trans,
            actions,
            merchant,
            log,
            transfer,
            order,
            depositTransaction,
            merchantLog,
            refers,
            depositAction
        ) {
            if (Object.keys(depositTransaction).length > 0) {
                this.depositTransaction.push(depositTransaction);
            }
            if (Object.keys(transfer).length > 0) {
                this.transfer.push(transfer);
            }
            if (Object.keys(merchant).length > 0) {
                this.merchant.push(merchant);
            }
            if (Object.keys(merchantLog).length > 0) {
                this.merchantLog = merchantLog;
            }
            if (Object.keys(actions).length > 0) {
                if(actions.action_detail && actions.action_detail.description){
                    let showDesc = "";
                    let desc = JSON.parse(actions.action_detail.description);
                    if(desc){
                        Object.keys(desc).forEach(function(key) {
                            showDesc += "- " + desc[key] + "\n";
                        });
                        actions.action_detail.description = showDesc;
                        this.ActionFields.push({
                            key: "reason_description",
                            label: "Lý do",
                        });
                    }
                }
                this.action.push(actions);
            }
            if (Object.keys(refers).length > 0) {
                // this.refers.push(refers);
                this.$emit("refers", {param: refers});
                this.refers = refers;
            }
            if (Object.keys(depositAction).length > 0) {
                // this.refers.push(refers);
                this.$emit("deposit", {param: depositAction});
                this.deposit = depositAction;
            }
            if (log) {
                if (Object.keys(log).length > 0) {
                    var col = log.row;
                    let i = 0;
                    log.data.forEach((value) => {
                        if (value) {
                            for (const [key] of Object.entries(value)) {
                                if (i < col) {
                                    let label = key;
                                    switch (key) {
                                        case "status":
                                            label = "Trạng thái";
                                            break;
                                        case "code":
                                        case "response_code":
                                        case "paygate_status":
                                        case "payment_status":
                                            label = "Mã lỗi";
                                            break;
                                        case "message":
                                        case "paygate_message":
                                            label = "Message";
                                            break;
                                        case "provider":
                                            label = "Nhà cung cấp";
                                            break;
                                        case "wallet_order_id":
                                        case "wallet_request_code":
                                            label = "Mã giao dịch";
                                            break;
                                        case "bank_transaction_id":
                                            label = "Mã GD Bank";
                                            break;
                                        case "extra_data":
                                            label = "Thông tin thêm";
                                            break;
                                        case "expired_at":
                                        case "expried_at":
                                            label = "Hạn sử dụng";
                                            break;
                                        case "serial":
                                            label = "Serial";
                                            break;
                                        case "price":
                                            label = "Số tiền";
                                            break;
                                        case "type":
                                            label = "Loại";
                                            break;
                                        default:
                                            label = key;
                                            break;
                                    }
                                    if(this.ignoreColumnLogFields.indexOf(key) < 0){
                                        this.LogField.push({
                                            key: key,
                                            label: label,
                                        });
                                        i++;
                                    }
                                }
                            }

                            value = this.objectRawDataToViewer(value);

                            this.log.push(value);
                        }
                    });
                }
            }
            if (Object.keys(trans).length > 0) {
                this.$emit("trans_status", {param: trans.status});
                this.$emit("type_link", {param: trans.type_link});
                this.$emit("transaction", {param: trans});
                this.$emit("order", {param: order});
                if (trans.refund_partial.length === 0) {
                    trans["refund_partial"] = "";
                }
                this.transaction.push(trans);
                this.transactionData = {...trans}
            }
            if (Object.keys(order).length > 0) {
                this.$emit("status", {param: order.status});
                this.order.push(order);
                this.orderData = {...order};
                Object.keys(order).forEach((key) => {
                    if (key == 'meta_data' && order[key]) {
                        this.OrderFields.push({
                            key: "meta_data",
                            label: "Chi tiết",
                        });
                    }
                    if (key == 'items' && order[key]) {
                        this.OrderFields.push({
                            key: "items",
                            label: "Chi tiết thẻ",
                        });
                    }
                });
            }
        },
        objectRawDataToViewer: function (value) {
            var newData = "";

            if (
                value.hasOwnProperty("card_info") &&
                Object.keys(value.card_info).length > 0
            ) {
                for (var key in value.card_info) {
                    newData +=
                        "<div><strong>" +
                        key +
                        "</strong>: " +
                        (value.card_info[key] == null ? "" : value.card_info[key]) +
                        "</div>";

                    if (!value.card_info["card_number"] && this.merchantLog[0].card_info) {
                        newData +=
                            "<div><strong>" +
                            "card_number" +
                            "</strong>: " +
                            (this.merchantLog[0].card_info.card_number == null ? "" : this.merchantLog[0].card_info.card_number) +
                            "</div>";
                    }

                    if (!value.card_info["card_name"] && this.merchantLog[0].card_info) {
                        newData +=
                            "<div><strong>" +
                            "card_name" +
                            "</strong>: " +
                            (this.merchantLog[0].card_info.card_name == null ? "" : this.merchantLog[0].card_info.card_name) +
                            "</div>";
                    }
                }

                value.card_info = newData;
            } else if (
                value.hasOwnProperty("bank_raw_response") &&
                Object.keys(value.bank_raw_response).length > 0
            ) {
                var newExtraData = "";

                for (var key1 in value.bank_raw_response) {
                    newData +=
                        "<div><strong>" +
                        key1 +
                        "</strong>: " +
                        (value.bank_raw_response[key1] == null
                            ? ""
                            : typeof value.bank_raw_response[key1] === "object"
                                ? JSON.stringify(value.bank_raw_response[key1])
                                : value.bank_raw_response[key1]) +
                        "</div>";
                }
                for (var key2 in value.extra_data) {
                    newExtraData +=
                        "<div><strong>" +
                        key2 +
                        "</strong>: " +
                        (value.extra_data[key2] == null ? "" : value.extra_data[key2]) +
                        "</div>";
                }
                value.extra_data = newExtraData;
                value.bank_raw_response = newData;
            }

            return value;
        },
        async syncStatus() {
            if (confirm("Xác nhận đồng bộ trạng thái đơn hàng?")) {
                this.$bus.$emit("show-loading", true);
                let response = await TransactionRepository.syncOrder({order_id: this.orderData.code});
                this.$bus.$emit("show-loading", false);
                let body = response.data;
                if (body.error_code === 0) {
                    alert('Đồng bộ thành công');
                    location.reload();
                } else {
                    alert(body.message);
                }
            }
        },
        renderLinkTransaction(tranCode) {
            return "/#/transactions/detail?transaction_id=" + tranCode;
        },
        renderLinkWallet(walletCode) {
            return "/#/transactions/detail?wallet_transaction_id=" + walletCode;
        }
    },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.card.mb-2,
.card.mb-3 {
    height: fit-content;
}

.col-md-6 {
    max-width: 50%;
}
</style>
<style lang="scss">
.header-log {
    color: white;
    padding: 8px;
}

.transactions-log {
    background-color: #65b2bd;
}

.action-log {
    background-color: #788587;
}

.transaction-deposit-log {
    background-color: #7540bb;
}

.order-log {
    background-color: #7181b5;
}

.merchant-log {
    background-color: #b78aa0;
}

.merchant-response-log {
    background-color: #797497;
}

.receiver-detail {
    background-color: #88b3a8;
}

td::before {
    text-align: left !important;
}

td.force-hidden {
    display: none !important;
}

.close_bt {
    display: inline-block;
    width: auto !important;
}

.viewmore {
    margin: 0 !important;
}
</style>
